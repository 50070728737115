@use '../../vars' as *;

@layer edwin {
  .modal-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #2a2a2acc;
  }

  .modal {
    position: relative;
    padding: $space-7x $space-9x;
    background: $white;
    border-radius: $radius-1x;
    box-shadow: $shadow-neutral-3;
  }

  .error {
    border: 1px solid $color-info-destructive;
  }

  .closeBtn {
    position: absolute;
    top: $space-3x;
    right: $space-3x;
  }
}
