@use '~@edwin-edu/ui/vars' as *;

.librarySearchContainer {
  display: flex;
  margin-left: auto;
  form {
    display: flex;
    position: relative;
  }
}
.formattedSearchInput {
  height: 30px;
  width: 330px;
  border-top: none;
  border-right: none;
  border-left: none;
  font-size: 1rem;
  font-weight: 700;
  padding-left: 5px;
}
.formattedSearchInput::placeholder {
  height: 30px;
  width: 455px;
  background: transparent;
  border-image: initial;
  border-bottom: 1px solid rgb(42, 42, 42);
  text-indent: 1px;
  color: rgb(42, 42, 42);
  color: $color-neutral-500;
  font-size: 1rem;
  font-weight: 400;
  margin-right: 4px;
  padding-left: 3px;
  letter-spacing: -0.2px;
}
.formattedSearchInput:focus {
  outline: none;
}
.formattedSearchInfo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background: $color-neutral-0 !important;
  border: 1.5px solid $color-base-300 !important;
  border-radius: 50%;
  box-shadow: none;
  cursor: default;
  user-select: none;
  padding: 0 !important;
  svg {
    position: absolute;
  }

  div {
    position: absolute;
    top: -7px;
    right: -8px;
    font-size: 1rem;
    color: $color-base-300;
    font-weight: 600;
    width: 10px;
    height: 25px;
  }
}

.questionMark {
  color: $color-base-300;
  position: absolute;
  top: -7px;
  right: -8px;
  font-size: 1rem;
  color: rgb(1, 122, 204);
  font-weight: 600;
  width: 10px;
  height: 25px;
}
.popoverHeader {
  text-align: left;
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 20px;
}
.popoverText {
  font-size: 18px;
  text-align: left;
  color: $color-neutral-400;
  margin: 0;

  span {
    font-weight: 600;
    color: $color-base-300;
  }
  span:nth-child(2) {
    font-style: italic;
  }
  strong {
    font-weight: 600;
    color: $color-base-300;
  }
  em {
    font-weight: 600;
    font-size: 16px;
    font-style: italic;
    color: $color-base-300;
  }
}
.panelCloseButton {
  position: absolute;
  right: 10px;
  top: 9px;

  svg {
    height: 14px;
  }
}
.dropdownContainer {
  margin-top: -6px;
  ul {
    max-width: unset;
  }
}
.infoContainer .hoverContainer span {
  font-weight: 700;
}
.infoContainer .infoTextContainer .infoText {
  display: none;
  position: absolute;
  padding: 15px;
  top: 68px;
  width: 442px;
  border-radius: 5px;
  overflow: visible;
  pointer-events: none;
  user-select: none;
  z-index: 500;
  position: absolute;
  pointer-events: unset;
  width: 330px;
  top: 53px;
  right: -10px;
  background-color: #ffffff;
  text-wrap: wrap;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

  :before {
    position: absolute;
    bottom: 100%;
    right: 15px;
    content: " ";
    height: 0;
    width: 0;
    pointer-events: none;
    border: 14px solid rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    margin-left: -13px;
  }
}


.infoContainer .hoverContainer:hover ~ .infoTextContainer .infoText {
  display: block;

}
.minChar {
  display: block !important;
}
