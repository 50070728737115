@use '~@edwin-edu/ui/vars' as *;

.button {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  gap: $space-2x;
  background-image: linear-gradient(90.7deg, rgb(255, 255, 255) 46.73%, rgba(255, 255, 255, 0.4) 106.42%);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .1);
  border: 1px solid #f4eaea;
  border-radius: 4px;
  padding: 20px 8px;
  height: 50px;
  width: 100%;

  font-weight: 400;
  font-size: 20px;
  color: $color-base-300;

  &:hover {
    color: $color-base-200;
    border: .5px solid $color-base-200;
  }

  & svg {
    width: 40px;
    height: auto;
  }

  @include svgHoverStyles($color-base-300, $color-base-200, $color-base-200);
}

.icon {
  display: flex;
  justify-content: center;
  flex: 0 0 36px;
}

.desmos {
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
}
