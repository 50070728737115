@use '~@edwin-edu/ui/vars' as *;

.questionText {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: $color-neutral-500;
  margin-left: 5px;

  // CKEditor formats
  & h1 {
    font-size: 28px;
    font-weight: 700;
  }

  & h2 {
    font-size: 24px;
    font-weight: 700;
  }

  & h3 {
    font-size: 24px;
  }

  & table,
  th,
  tr,
  td {
    border: 1px solid $color-neutral-500;
  }

  & th,
  tr,
  td {
    padding: 6px;
  }

  & ul {
    list-style: initial;
    li{
      margin-left: 50px;
      &::marker {
      font-size: 18px;
      }
    }
  }

  & ol {
    list-style: decimal;
    li{
      margin-left: 50px;
    }
  }


  & img {
    display: inline;
  }
}

.mid {
  font-size: 1.625rem;
}

.large {
  font-size: 2.125rem;
}

.highlighting {
  background-color: $color-base-200;
  padding: 4px;
  border: 1px solid $color-neutral-400;
  border-radius: $radius-2x;
}
