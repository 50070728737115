.modal {
  margin-top: 100px;
  overflow: scroll;
  max-height: 90vh;
}
.inputTitle {
    font-size: 20px;
    font-weight: 300;
    border: 0;
  
    &:focus {
      outline: 0;
    }
}
.inputDescription {
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
}
.createCollectionButton {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2a2a2a;
}
.emptyCreateButton {
  font-weight: 800;
}
.newCollection {
  padding-bottom: 10px;
  border-bottom: 1px solid #d8d8d8;
}
.collectionNameContainer {
  margin-top: 10px;
}
.teacherOnlyContainer {
  width: 350px;
  display: inline;
  span {
    color: #017acc;
    font-size: 14px;
    font-weight: 600;
    padding-top: 2px;
  }
}
.teacherOnlyOption {
  margin: 0 10px;
  float: right;
}
.teacherOnlyLabel {
  font-size: 14px;
  font-weight: 400;
  color: #2a2a2a;
  padding: 0 10px;
}
.subjectDropdown {
  border: 1px solid rgb(1, 122, 204);
  border-radius: 20px;
  font-size: 16px;
  font-weight: 600;
  button {
    width: 100%;
    text-align: left;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    color: #2a2a2a;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    color: rgb(1, 122, 204);
    box-shadow: none;
    cursor: pointer;
    min-height: 38px;
    transition: border-color 100ms ease-in 0s;
    padding: 0 10px;
    overflow-y: scroll;
  }
}
.menu {
  background-color: red;
  opacity: 0.5;
}

.dropdown {
  position: relative;
  z-index: 9999;

  &.dropDownOpen {
    .menu {
      z-index: 9999; // Ensure the dropdown menu appears above other elements
    }
  }
}

.richTextEditorDisabled {
  pointer-events: none;
}