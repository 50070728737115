@use '~@edwin-edu/ui/vars' as *;

.chevron {
  position: absolute;
  width: 2rem;
  text-align: center;
  height: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: none;
  }

  polyline {
    stroke-width: 1px;
    stroke: $color-neutral-500;
  }

  :hover {
    polyline {
      stroke: rgba($color-neutral-500, 0.6);
    }
  }
}

.chevron.isOpen {
  svg {
    transform: rotate(-180deg);
  }
}

.chevron.primary {
  polyline {
    stroke: $color-base-300;
  }

  :hover {
    polyline {
      stroke: rgba($color-base-300, 0.6);
    }
  }
}
