@use '../../../../../node_modules/@edwin-edu/ui/vars' as *;

.content {
  padding: 0 10px 20px 15px;
  position: relative;
}

.top {
  display: flex;
  margin-top: -20px;
  margin-bottom: 25px;
}

.thumbnailColumn {
  flex: 0 0 auto;
  & svg {
    width: 78px;
    height: 100px;
  }

  & img {
    width: 78px;
    height: 100px;
    border: 1px solid $color-base-300;
  }

  & {
    margin-top: 25px;
  }
}

.collectionThumbColumn {
  flex: 0 0 100px;
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 12px;
}

.mainColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 50px 0 20px;
}

.description {
  margin-top: 5px;
  font-size: 20px;
  font-weight: 400;
  color: $color-neutral-500;
  letter-spacing: -0.4px;
}

.bottom {
  margin: 20px -20px 0 0;
  padding-right: 20px;
  overflow-y: auto;
  @include scrollbar();
}

.tags {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.4px;
  color: $color-neutral-500;

  & span {
    font-weight: 400;
    color: $color-neutral-500;
  }
}

.teacherOnly {
  margin-top: 1.5rem;
}

.title {
  margin-top: 24px;
  color: $color-base-300;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: -0.6px;
}

.curriculumLabel {
  font-weight: 700;
}
.curriculumContent {
  font-weight: 400;
  max-height: 300px;
  margin-top: $space-1x;
}

.subjectsList {
  list-style-type: none;
  margin-left: $space-6x;
}

.subject {
  font-weight: bold;
  font-style: italic;
  margin-bottom: 10px;
}

.strandsList {
  list-style-type: none;
  font-weight: normal;
  font-style: normal;
  margin-left: $space-6x;
}

.strand {
  &::before {
    content: '-';
    margin-right: 5px;
  }

  & {
    margin-bottom: 10px;
  }
}

.overallExpList {
  list-style-type: disc;
  margin-top: 10px;
  text-indent: 0;
  margin-left: $space-10x;
}

.overallExp {
  margin-bottom: 10px;
}

.specificExpList {
  list-style-type: disc;
  margin-top: 10px;
  margin-left: $space-6x;
}

.specificEx {
  margin-bottom: 10px;
}
