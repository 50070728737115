@use '~@edwin-edu/ui/vars' as *;

.caratIcon {
  margin: auto 0 auto 7px;
  padding: 1px 1px 0 0;
  width: 12px;
  path {
    stroke: #fff !important;
  }
}

.errorContainer {
  max-height: 190px;
  padding: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbar;
}
