@use '~@edwin-edu/ui/vars' as *;

//Tags Filter
.tagsFilter {
  display: flex;
  flex-direction: row-reverse;
}

.container {
  position: relative;
}

// Collection Options
.collectionsOptions {
    margin: 25px 0 ;
}

.divider {
    border-left: 1px solid rgb(188, 188, 188);
    margin: 0 10px;
}

// Collection Card Styles

.top {
    height: 202px;
    position: relative;

    :hover {
        visibility: visible;
        transition-delay: 0.5s;
    }
}

.collectionCard {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 310px;
    background: none;
    position: relative;
    perspective: 1000px;
    overflow: hidden;
    color: #FFF;
}

.cardSideNelson,
.cardSideUser {
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #2a2a2a;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
}

.cardSideUser {
    background: #004672;
}

.frontBackgroundNelson,
.frontBackgroundUser {
    position: absolute;
    height: 104%;
    width: 104%;
    top: -2%;
    left: -2%;
    background-size: cover;
    background-position: center;
    filter: blur(1px);
}

.edwinLogo {
    position: absolute;
    top: 0;
    width: 100%;
    background: linear-gradient(to right, rgba(1, 122, 204, 0) 20%, #017ACC 80%);
    padding: 5px 0;
}

.collectionCardTitle {
    font-size: 24px;
    letter-spacing: -0.6px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    line-height: 26px;
    margin: 25px 10px 3px;
    height: 56px;
    overflow: hidden;

    &:hover {
        color: #2AAEFF;
        transition: color 150ms ease-out;
    }
}

.titlePopoverText {
    font-size: 17px;
    cursor: pointer;
    color: $color-base-300;
}

.updatedAt {
    font-size: 12px;
    letter-spacing: -0.3px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 10px;
}

.resizeContainer {
    position: absolute;
    top: 100px;
    left: 40%;
    transform: scale(1.5);
}

.bottom {
    position: relative;
    height: 109px;
    padding: 15px 7px 0;
}

.info,
.infoNotAvailable {
    font-size: 14px;
    font-style: italic;
}

.infoNotAvailable {
    text-decoration: underline;
}

.teacherOnly {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.edited {
    position: absolute;
    top: 0;
    left: -25px;
    width: 125px;
    font-style: italic;
    text-align: center;
    letter-spacing: -0.04px;
    transform: rotate(-23deg);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7) 25%, rgba(0, 0, 0, 0.3) 70%);
    pointer-events: none;
    padding: 0 70px 0 0;
    span {
        font-size: 8px;
        line-height: 10px;
        font-style: italic;
        letter-spacing: -0.04px;
        padding: 1px 0px;
    }
}

.select {
    top: -8px;
    right: -8px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgb(20, 208, 255), 0 0 3px 0 rgba(42, 42, 42, 0.2);
    z-index: 4;
}

.selected::after {
    content: '';
    width: 5px;
    height: 10px;
    border-style: solid;
    border-color: #017ACC;
    border-image: initial;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
    margin-top: -3px;
    right: 0px;
}
.selectedCollection, .collectionCardContainer {
    position: 'relative';
    margin: 5px;
}
.selectedCollection {
    box-shadow: rgba(20, 208, 255, 0.25) 0px 0px 0px 8px;
    margin: 5px;
    position: relative;
}

.selectedButtonStyles, .unSelectedButtonStyles {
    font-weight: 600;
    color: #2a2a2a;
    line-height: 24px;
    font-size: 16px;
}
.unSelectedButtonStyles {
    opacity: 0.6;
    &:hover {
        cursor: not-allowed;
    }
}
.deleteWarningMessage {
    margin-top: 20px;
}
.cancelButton {
    color: rgb(1, 122, 204);
    font-size: 14px;
    font-weight: 400;
    &:hover {
        color: #004D80;
    }
}
