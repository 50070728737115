@import '~@edwin-edu/ui/vars';

.modalContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.errorContainer {
  display: flex;
  position: relative;
  padding: 80px;
  background: $white;
  border-radius: $radius-1x;
  box-shadow: $shadow-neutral-3;
}

.barrier {
  width: 1px;
  height: 152px;
  margin: 0 45px 0 40px;
  background: $color-base-300;
  opacity: 0.6;
}

.backBtn {
  height: 45px;
  width: 111px;
  margin: 28px auto 0 0;
}

.teacherOnly {
  margin: 65px 0 0;
}
.studentError {
  display: flex;
  flex-direction: column;
}

.errorText {
  color: $color-base-300;
  letter-spacing: -1px;
}
.errorType {
  letter-spacing: -1px;
  font-size: 30px;
  font-weight: 600;
}
.errorTitle {
  font-size: 36px;
  font-weight: 300;
}
.errorInfo {
  color: $color-neutral-500;
  font-size: 21px;
  font-weight: 300;
  margin-top: 6px;
}
