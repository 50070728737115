@use '@edwin-edu/ui/vars' as *;

.tocListItem {
  list-style-type: disc;
  color: $color-base-300;
  margin-top: $space-2x;
}

.tocButton {
  text-align: left;
  color: $color-base-300;
  font-size: 20px;
  font-weight: 600;

  & :hover {
    color: $color-base-400;
  }
}

.tocButton.level2 {
  font-weight: 400;
}

.infoIcon {
  width: 24px;
  height: 24px;

  & path {
    fill: $color-base-300;
  }

  & circle {
    stroke: $color-base-300;
    stroke-width: 2;
  }
}

.searchInput {
  border: none;
  border-bottom: 1px solid $color-neutral-100;
  border-radius: 0;
  padding-left: $space-2x;
  height: 2em;
}

.searchNavButton {
  @include svgHoverStyles($color-base-300, $color-neutral-500, $color-neutral-500);
}

