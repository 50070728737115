@use '@edwin-edu/ui/vars' as *;

.libraryPopover {
  @media (max-width: 1018px) {
    right: -80px;
  }
}

.title {
  color: $color-neutral-300;
  font-weight: 600;
  margin: $space-3x 0 0;
}

.hr {
  margin: $space-2x 0 $space-3x !important;
}

.added {
  position: absolute;
  display: flex;
  right: 0;
  top: 6px;
  color: #4a4a4a;
  font-style: italic;
  background: #fff;
  padding: 0 0 0 2px;
}

// TODO: added animation. should switch to framer-motion
.added.entering {
}

.added.exiting {
}

.listItem {
  height: 32px;
  position: relative;
  display: flex;
}

.input {
  width: calc(100% - 80px);
  background: $color-base-300;
  border: none;
  padding: 1px 5px;
  color: #fff;
  font-size: 16px;
  margin: 2px 0;
  outline: none;

  ::placeholder {
    color: rgba($white, 0.6);
  }
}

.collectionButton {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6px 0;
  color: $color-base-300;
  font-weight: normal;
}

.disabledCollectionButton {
  //  $ {
  //  buttonStyles
  //};
  color: $color-base-200;
}

//const CollectionButton = styled(ClickButton)`
//${buttonStyles}
//`
.panelButton {
  position: absolute;
  right: 50px;
  top: 19px;
  margin: 0;
}

.bottomButton {
  position: absolute;
  top: initial;
  bottom: 19px;
  right: 19px;
  font-weight: 700;
}

.bottomButtonDisabled {
  visibility: hidden;
}

.newFormButton {
  width: 40px;
  height: 30px;
  border: 1.5px solid $color-base-300;
  vertical-align: top;
  margin: 0 0 0 5px;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancelButton {
  border-radius: 42px 0 0 42px;
  padding-left: 3px;
}

.submitButton {
  border-radius: 0 42px 42px 0;
  padding-right: 5px;
}

.readerDropdownContainer {
  min-width: 300px;
  max-width: 400px;
  padding: 19px;
  overflow-y: auto;
  max-height: 365px;
  @include scrollbar();
}
