@use '~@edwin-edu/ui/vars' as *;

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 165px;
  height: 191px;
  border-radius: 8px;
  border: 2px solid #2aaeff;
  padding: $space-4x 0;
  box-shadow: $shadow-neutral-2;
}
