@use '~@edwin-edu/ui/vars' as *;

.dividerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $space-1x;
  width: 100%;
}

.divider {
  flex: 1;
  height: 1px;
  background-color: $color-neutral-300;
}