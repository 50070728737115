@use '../../vars' as *;

.menu {
  position: absolute; // relative parent container is in dropdown.tsx
  top: $space-4x + $space-5x; // (button-y-padding x 2) + button line-height
  min-width: 273px;
  max-width: 337px;
  width: max-content;
  padding: $space-5x 0;
  background: $white;
  border-radius: $space-2x;
  box-shadow: $shadow-neutral-2;
  transform: translateY($space-2x);
  z-index: 1;

  button {
    display: block;
    width: 100%;
    height: 28px;
    padding: 0 $space-4x;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background: $color-base-300;
      color: $white;
    }

    &:focus {
      box-shadow: none;
      border-radius: 0;
    }
  }
}

.menu.up {
  top: initial;
  bottom: $space-4x + $space-5x + $space-4x; // (button-y-padding x 2) + button line-height + some margin
}

.menu-title {
  & {
    color: $color-neutral-300;
    margin: 0 $space-4x $space-3x;
    padding-bottom: $space-3x;
    border-bottom: 1px solid $color-neutral-300;
    font-weight: 700;
  }
  @include body-medium(false);
}

.menu-content {
  max-height: 540px;
  overflow-y: auto;
  @include scrollbar();
}

.menu-group {
  > div:first-child {
    height: 28px;
    padding-left: $space-4x;
    @include h5(false);
  }

  button {
    padding-left: $space-6x;
  }
}

.divider {
  height: 0;
  margin: $space-2x $space-4x;
  border-bottom: 1px solid $color-neutral-300;
}
