@import '~@edwin-edu/ui/vars';

.ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list {
  padding-top: 1px;
  overflow-y: auto;
  overflow-x: hidden;
  @include scrollbar;
}
