@use '../../vars' as *;

.container {
  position: relative;
  display: flex;

  input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    flex: 0 0 8px;
    width: 8px;
    height: 8px;
    border-radius: $radius-half;
    margin: 0;
    border: 1px solid $color-neutral-500;
    outline: none;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: -8px;
      left: -8px;
      width: 24px;
      height: 24px;
    }

    & ~ svg {
      display: none;
      pointer-events: none;
    }

    & ~ span {
      flex: 1 1 auto;
      line-height: 24px;
      letter-spacing: -0.3px;
      -webkit-user-select: none;
      user-select: none;
      cursor: pointer;
      transition: color 150ms;

      &:hover {
        color: $color-base-400;
      }
    }

    &:checked,
    &:indeterminate {
      border: none;

      & ~ svg {
        display: inline-block;
        position: absolute;
        left: -2px;
        top: -3px;
      }
    }

    &:indeterminate {
      & ~ svg {
        top: -1px;
      }
    }

    &.has-label {
      margin-top: 9px;
      margin-right: 6px;

      &::after {
        top: -1px;
      }

      &:checked {
        & ~ svg {
          left: -2px;
          top: 6px;
        }

        & ~ span {
          font-weight: 700;
        }
      }
    }

    &:disabled {
      opacity: 0.6;

      & ~ span {
        opacity: 0.6;
      }
    }

    &.white {
      border: 1px solid $white;

      &:checked,
      &:indeterminate {
        border: none;

        & ~ svg {
          path {
            fill: $white;
          }
          line {
            stroke: $white;
          }
        }
      }

      & ~ span {
        color: $white;

        &:hover {
          color: $color-neutral-100;
        }
      }
    }
  }
}
