@use '~@edwin-edu/ui/vars' as *;

.modal {
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 12px;
  line-height: 1rem;
  border-bottom: 1px solid rgb(188, 188, 188);
  padding: 20px;
  width: 286px;
}
.pencil {
  display: inline-block;
  margin: 0 5px;
  svg {
    height: 12px;
    transform: translateY(2px);
    g {
      fill: $color-base-300;
    }
  }
}
