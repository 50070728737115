@use '~@edwin-edu/ui/vars' as *;

.container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  gap: $space-3x;
  min-height: 100px;
  background-image: linear-gradient(90.7deg, rgb(255, 255, 255) 46.73%, rgba(255, 255, 255, 0.4) 106.42%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-sizing: border-box;
}

.noOutline {
  box-shadow: none;
}

.divider {
  background-color: rgb(124, 124, 124);
  width: 1px;
  margin: 0 10px;
  height: 70px;
}

.xsmall {
  width: 600px;
  height: 62px;
}

.small {
  width: 600px;
  height: 85px;
}

.photoContainer {
  flex: 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo {
  min-width: 61px;
  min-height: 81px;
  width: 61px;
  height: 81px;
  border: 0.5px solid rgb(1, 122, 204);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px;
}

.small .photo {
  width: 42px;
  height: 56px;
  min-width: 42px;
  min-height: 56px;
}

.icon {
  border: none;
  box-shadow: none;
}

.bannerContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 120px;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.banner {
  position: absolute;
  top: 3px;
  left: -11px;
  width: 120px;
  padding-right: 25px;
  background: rgb(1, 122, 204);
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  transform: rotate(-24.4deg);
}

.tags {
  display: inline-block;
  margin-right: 0.5rem;
  padding: 0px 0.5rem;
  border: 0.5px solid rgb(188, 188, 188);
  border-radius: 4px;
  line-break: anywhere;
  line-height: 21px;
  color: rgb(74, 74, 74);
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 1.5rem;
  width: max-content;
}

.title {
  @include body-large(false);
  span {
    color: $color-base-300;
    font-weight: 600;
    line-height: 1.25;
    @include lineClamp(3);
  }
}

.subTitle {
  margin-left: 10px;
  font-size: 15px !important;
  font-style: italic;
  font-weight: 200;
}

.description {
  margin-top: 8px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.3px;
  // max-height: 50px;
  color: rgb(124, 124, 124);
}
