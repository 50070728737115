@use '../../vars' as *;

.editorParentContainer {
  border: 0.5px solid $grey-200;
  overflow: hidden;
}

.editorControls {
  height: $space-8x;
  box-shadow: 0 1px 2px 0 $grey-200;
}

$placeholder: 'public-DraftEditorPlaceholder-root';
$placeholder-focus: $placeholder + ' public-DraftEditorPlaceholder-hasFocus';

.editorContainer {
  @include body-medium(false);
  & {
    color: $black;
    white-space: pre-line;
  }

  div[class='#{$placeholder}'],
  div[class='#{$placeholder-focus}'] {
    padding: $space-4x $space-2x;
    color: $grey-200;
    z-index: unset;
  }
  div[role='textbox'] {
    padding: $space-4x $space-2x;
    cursor: text;
  }
  ol, ul {
    margin: 0;
  }
  ul li:before {
    display: none;
  }
}

.hidePlaceholder {
  div[class='#{$placeholder}'],
  div[class='#{$placeholder-focus}'] {
    display: none;
  }
}

.readOnly {
  padding: 0;
  cursor: unset;
}

.editorIconButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: $space-9x;
  padding: 0 $space-3x;
  &:hover {
    background-color: $grey-100;
  }
  &:focus {
    outline: 2px solid $color-base-200;
    outline-offset: -2px;
  }
}
