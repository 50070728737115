@use '../../../vars' as *;

.welcomeDesc {
  font-weight: 500;
  font-size: 18px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.message {
  margin-bottom: 25px;
  line-height: 1.2;
  text-align: left;
}

.spinnerText {
  display: block;
  color: $color-base-300;
  font-size: 13px;
  font-style: italic;
  margin: 0 auto;
  text-align: center;
}

.spinnerText {
  display: block;
  color: $color-base-300;
  font-size: 13px;
  font-style: italic;
  margin: 0 auto;
  text-align: center;
}

.signInWithEmail {
  cursor: pointer;
  text-decoration: underline;
}

.displayEmailFieldContainer {
  display: block;
}

.signInInfo {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.6px;
  line-height: 22px;
  width: 250px;
  text-align: center;
  margin: $space-6x auto $space-4x;
}

.orRow {
  display: block;
  position: relative;
  width: 160px;
  border-bottom: 1px solid $color-neutral-300;
  height: 13px;
  margin: $space-4x 0 $space-6x;
}

.orRowText {
  //span
  line-height: 1.25;
  padding: 3px 10px 0;
  font-size: 12px;
}

.anchor {
  &:focus {
    outline: 2px solid $color-base-300;
  }
}
.margin {
  height: 11px;
}

.center {
  text-align: center;
}

.authInput {
  //input
  outline: none;
  height: 41px;
  margin: 4px auto 4px;
  padding: 0 16px;
  width: 325px;
  border-radius: 20px;
  text-align: center;
  letter-spacing: -0.3px;
  background: rgba($color-base-300, 0.25);
  border: none !important;

  &::-moz-placeholder,
  -webkit-input-placeholder {
    font-style: italic;
    color: $color-neutral-500;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0px;
    opacity: 1;
  }
}

.continueButton {
  //Button
  padding: 1px 18px 2px;
  margin: 5px auto 15px;
  border: 1px solid $color-neutral-400 !important;
  letter-spacing: -0.2px;
  font-size: 12px;
}

.bottomRow {
  //form
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;

  span {
    a {
      color: $color-base-300;
      text-decoration: underline;
      font-weight: 700;
      &:focus {
        outline: 2px solid $color-base-300;
      }
    }
  }
}

.helpLink {
  //a
  font-size: 12px;
  color: $color-neutral-500 !important;
  text-decoration: none;
  letter-spacing: -0.2px;
  margin-bottom: 15px;
  -webkit-user-drag: none;
  margin-top: 5px;
  
  &:focus {
    outline: 2px solid $color-base-300;
  }
}
