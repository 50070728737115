@use '~@edwin-edu/ui/vars' as *;

.emptyStateContainer {
  display: flex;
  align-items: center;
  height: 750px;
  flex-direction: column;
  font-size: 18px;
  margin-top: 70px;
}
.content {
  width: 430px;
  text-align: center;
  color: $color-neutral-500;
}
.emptyStateTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}
.emptyStateDescription,
.emptyStateSubDescription {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3px;
}
.emptyStateSubDescription {
  margin-top: 20px;
}
.buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
