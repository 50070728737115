@use '@edwin-edu/ui/vars' as *;

@mixin titleStyles {
  svg * {
    stroke: $color-base-300;
  }
  &:hover {
    color: rgb(0, 77, 128);
    transition: color 150ms ease-out, background 150ms ease-out, border 150ms ease-out, opacity 150ms ease-out, transform 150ms ease-out;
    svg * {
      stroke: rgb(0, 77, 128);
      transition: stroke 150ms ease-out, background 150ms ease-out, border 150ms ease-out, opacity 150ms ease-out, transform 150ms ease-out;
    }
  }
}

.titleStyles {
  @include titleStyles;
}

.BrowseBySubject {
  display: flex;
  flex-direction: column;
  gap: $space-4x;
}

.description {
  display: block;
  font-weight: 600;
  max-width: 800px;
  line-height: 1.25;
  margin-bottom: $space-4x;
}

.listHeader {
  position: relative;
  margin: 0.75rem 0;
  font-size: 18px;
  line-height: 1.1;
  word-break: break-word;
  text-align: left;
  padding-right: 1.25rem;
  @include titleStyles;
}

.viewAll {
  display: inline-block;
  font-weight: 600;
  @include titleStyles;
}

.listLevel2 {
  font-size: 1rem;
  padding-right: 1rem;
  padding-left: 0.5em;
  list-style: "- " outside;
  margin-bottom: 1.5rem;
  line-height: 1.25;

  > li {
    margin: 0.75rem 0;
  }
}

.assessmentList {
  list-style: decimal;
}

.assessmentListItem {
  list-style: inherit;
  @include titleStyles;
}
