@use '~@edwin-edu/ui/vars' as *;

.listItem {
  position: relative;
  flex: 0 0 85px;
  background: linear-gradient(
                  90.7deg,
                  #ffffff 46.73%,
                  rgba(255, 255, 255, 0.4) 106.42%
  );
  mix-blend-mode: normal;
  opacity: 0.9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  :hover {
    text-decoration: none;
  }
}

.link {
  text-decoration: none !important;
  display: flex !important;
  padding: 10px 13px 0;
  line-height: 0.9;
  height: 100%;
}

.thumbnailColumn {
  position: relative;
  flex: 0 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}

.panelDate {
  display: block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.3px;
  max-height: 18px;
  color: $black;
  line-height: 1;
}

.title {
  font-weight: 600;
  margin-bottom: -$space-1x;
  line-height: 1;
  margin-top: 3px;
  min-height: 19px;;
}

.subject {
  font-size: 12px;
  color: $black;
  letter-spacing: -0.4px;
  margin-top: 3px;
  line-height: 1.25;
}

.iconsRow {
  position: absolute;
  display: flex;
  gap: 12px;
  right: 12px;
  bottom: 8px;
}

.teacherOnlyIcon {}

.teacherToolsIcon {
  margin-bottom: 2px;
}

.ribbonSmall {
  font-size: 12px !important;
  line-height: 15px !important;
  left: -8px !important;
}

.skeleton {
  padding: 4px 0 0 12px;

  svg,
  span,
  img {
    border: none !important;
  }
}

.dateSkeleton {
  margin-top: 8px;
}
