@use '../../vars' as *;

.container {
  position: relative;
}
.button {
  gap: $space-3x;
  padding-right: $space-3x;
  padding-left: $space-3x;

  &.open {
    svg:last-child {
      transform: rotate(180deg);
    }
  }
}

@mixin chevron-style($stroke) {
  svg {
    path {
      stroke: $stroke;
    }
  }
}

.primary {
  &.open {
    @include chevron-style($color-base-300);
  }

  &:hover {
    color: $color-base-400;
    @include chevron-style($color-base-400);
  }

  &.selected {
    border: $color-base-300;
    background-color: $color-base-300;
    color: $white;
    @include chevron-style($white);
  }
}

.secondary {
  border: 1px solid $color-neutral-400;
  color: $color-neutral-500;

  &.open {
    background: $color-neutral-400;
    color: $color-neutral-0;
    @include chevron-style($color-neutral-0);
  }

  &:hover {
    background: $color-neutral-0;
    border: 1px solid $color-base-300;
    color: $color-base-400;
    @include chevron-style($color-base-400);
  }

  &.selected {
    border: $color-base-300;
    background-color: $color-base-300;
    color: $white;
    @include chevron-style($white);
  }

  &:disabled {
    border: 1px solid $color-neutral-500;
    color: $color-neutral-500;
    @include chevron-style($color-neutral-500);
  }
}

.tertiary {
  background: $color-neutral-0;
  border: 1px solid $color-neutral-400;
  color: $color-neutral-500;
  font-weight: 600;

  &.open {
    background: $color-neutral-400;
    border: 1px solid $color-neutral-400;
    color: $color-neutral-0;
    @include chevron-style($color-neutral-0);
  }

  &:hover {
    background: $color-neutral-0;
    border: 1px solid $color-base-300;
    color: $color-base-300;
    @include chevron-style($color-base-300);
  }

  &.selected {
    border: $color-base-300;
    background-color: $color-base-300;
    color: $white;
    @include chevron-style($white);
  }

  &:disabled {
    background: $color-neutral-0;
    border: 1px solid $color-neutral-500;
    color: $color-neutral-500;
    @include chevron-style($color-neutral-500);
  }
}

.dropdown {
  position: relative;

  &.open {
    .menu {
      z-index: 9999; // Ensure the dropdown menu appears above other elements
    }
  }
}

.richTextEditorDisabled {
  pointer-events: none; // Disable pointer events when dropdown is open
  display: none; // Hide the dropdown when the editor is disabled
}
