@use '~@edwin-edu/ui/vars' as *;

.container {
  @include gradientBorderStyles(4, 0);
}

.fileItemContainer {
  img[class^="Thumbnail"] {
    border: none;
    box-shadow: none;
  }
}

.bannerContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 120px;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.banner {
  position: absolute;
  top: 3px;
  left: -11px;
  width: 120px;
  padding-right: 25px;
  background: rgb(1, 122, 204);
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  transform: rotate(-24.4deg);
}

.dragHandle {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 36px;
  height: 36px;
  background: linear-gradient(135deg, transparent 0%, transparent 50%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.6) 100%);
  border-radius: 0px 0px 3px;
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center; /* Center the image inside the div */
}

.dragDrop {
  position: absolute;
  right: 2px;
  bottom: 4px;
  width: 14px;
  height: 14px;
  cursor: grab;
}

.indicator {
  top: -4px;
  right: -4px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 0 3px $blue-200, 0 0 3px 0 rgba(42, 42, 42, 0.2);
}

.selectedIndicator {
  background: $blue-200;
  &::after {
    content: '';
    width: 5px;
    height: 10px;
    border-style: solid;
    border-color: $white;
    border-image: initial;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
    margin-top: -3px;
    right: 0px;
    display: absolute;
  }
}

.selected {
  box-shadow: inset 0 0 0 3px $blue-200;
  transition: none;
}

.selectedCollection {
  position: relative;
  box-shadow: rgba(20, 208, 255, 0.25) 0px 0px 0px 2px;
  pointer-events: none;
}
