@use '../../vars' as *;

@layer edwin {
  .table-container {
    display: flex;
    flex-direction: column;
    background-color: $white;
    overflow: auto;
    font-family: inherit;
  }

  .large {
    @include body-large(false);
    .tr {
      height: $space-14x;
    }
  }
  .medium {
    @include body-medium(false);
    .tr {
      height: $space-12x;
    }
  }
  .small {
    @include body-small(false);
    .tr {
      height: $space-10x;
    }
  }
  .tag {
    @include body-tag(false);
    .tr {
      height: $space-10x;
    }
  }

  .thead {
    .tr:first-child {
      color: $color-base-300;
      font-weight: 700;
      -webkit-user-select: none;
      user-select: none;
    }
  }

  .tr {
    display: flex;
  }

  .th,
  .td {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    min-width: 0;
    padding: 0 $space-4x;
    border-bottom: 1px solid #bcbcbc;

    &.grow {
      flex: 1 0 auto;
    }

    &.shrink {
      flex: 0 1 auto;
    }

    &.left {
      justify-content: start;
    }
    &.center {
      justify-content: center;
    }
    &.right {
      justify-content: end;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      
      &[class*="checkbox-container"] {
        overflow: visible;
      }
    }
  }

  .chevron-group {
    display: flex;
    flex-direction: column;
    margin-left: $space-2x;

    &.ascending {
      svg:first-child {
        path {
          stroke: $color-neutral-500;
        }
      }
    }
    &.descending {
      svg:last-child {
        path {
          stroke: $color-neutral-500;
        }
      }
    }
  }

  .checkbox {
    accent-color: $color-base-300;
  }

  .expandedRow {
    padding: $space-4x;
    border-bottom: 1px solid #bcbcbc;
    > div {
      flex: 1;
      span {
        word-break: break-word;
      }
      span:first-child {
        flex: 1;
      }
      span:last-child {
        flex: 2;
      }
    }
  }

  .empty {
    height: 400px;
  }
}