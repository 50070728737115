@use "../../vars" as *;

.tooltip {
  color: $white;
  background: $color-neutral-400;
  padding: $space-1x $space-2x;
  border-radius: $radius-half;
  font-size: $font-size-1;
  line-height: $line-heights-10;
  font-weight: 700;
  box-shadow: 0px 2px 4px 0px rgba(74, 74, 74, 0.25);
  border-radius: 4px;
  position: relative;
}
