@use '~@edwin-edu/ui/vars' as *;

.title {
  position: relative;
  display: inline-block;
  color: rgb(42, 42, 42);
  line-height: 1.1;
  word-break: break-word;
  text-align: left;
  padding-right: 1.25rem;
}
.teacherOnly {
  margin: 0 0 0 7px;
}
