@use '../../vars' as *;

.content {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: inherit;
  max-width: inherit;
  height: inherit;
  max-height: inherit;
}

.contentPopover {
  padding: $space-2x $space-3x;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.25s ease;

  position: fixed;
  background-color: $color-neutral-0;
  border-radius: $radius-1x;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  animation: 75ms ease-out forwards;

  .content:hover & {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.5s;
  }
}
