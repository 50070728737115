@use '@edwin-edu/ui/vars' as *;

.printIcon{
  cursor: pointer;
  min-width: 28px;
  min-height: 28px;
  margin-left: 10px;
}

.printModal{
  position: absolute;
  width: 410px;
  top: 35px;
  right: -8px;
  border-radius: 3px;
  padding: 40px 20px 75px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 3px 0px;
  z-index: 99;
  background: white;
}

.printTermsIcon{
  position: absolute;
  left: 15px;
  top: 50px;
}

.infoContainer{
  position: relative;
  padding-left: 40px;
}

.disclaimer1{
  margin-bottom: 10px;
}

// .disclaimer2{}

.acceptButton{
  position: absolute;
  right: 20px;
  bottom: 20px;
}