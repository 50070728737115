.headerContainer {
  font-size: 12px;
}

.pill {
  padding: 5px 0;
  margin-right: 2px;
  display: inline-block;
  width: 30px;
  height: 12px;
  cursor: default !important;
}

.low {
  background-color: rgb(255, 0, 83);
}

.medium {
  background-color: rgb(254, 223, 68);
}

.high {
  background: rgb(71, 255, 158);
}

.none {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(202, 202, 202);
}

.interactive.none {
  border: 2px solid rgb(202, 202, 202);
}

.pillLeft {
  border-radius: 100px 0 0 100px;
}

.pillRight {
  border-radius: 0 100px 100px 0;
}

.interactive {
  width: 45px;
  height: 18px;
  cursor: pointer !important;
}

.interactive:hover {
  opacity: 0.5;
}
