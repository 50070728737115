/* CSSTransition doesn't work with scss because classnames are randomly generated */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 150ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms;
}


.fade-top-enter {
    transform: translateY(20%);
    opacity: 0;
}
.fade-top-enter-active {
    transform: translateY(0%);
    opacity: 1;
    transition: transform 250ms, opacity 150ms;
}
.fade-top-exit {
    transform: translateY(0%);
    opacity: 1;
}
.fade-top-exit-active {
    transform: translateY(20%);
    opacity: 0;
    transition: transform 150ms, opacity 150ms;
}

.fade-right-enter {
    transform: translateX(-20%);
    opacity: 0;
}
.fade-right-enter-active {
    transform: translateX(0%);
    opacity: 1;
    transition: transform 250ms, opacity 150ms;
}
.fade-right-exit {
    transform: translateX(0%);
    opacity: 1;
}
.fade-right-exit-active {
    transform: translateX(-20%);
    opacity: 0;
    transition: transform 150ms, opacity 150ms;
}

.fade-bottom-enter {
    transform: translateY(-20%);
    opacity: 0;
}
.fade-bottom-enter-active {
    transform: translateY(0%);
    opacity: 1;
    transition: transform 250ms, opacity 150ms;
}
.fade-bottom-exit {
    transform: translateY(0%);
    opacity: 1;
}
.fade-bottom-exit-active {
    transform: translateY(-20%);
    opacity: 0;
    transition: transform 150ms, opacity 150ms;
}

.fade-left-enter {
    transform: translateX(20%);
    opacity: 0;
}
.fade-left-enter-active {
    transform: translateX(0%);
    opacity: 1;
    transition: transform 250ms, opacity 150ms;
}
.fade-left-exit {
    transform: translateX(0%);
    opacity: 1;
}
.fade-left-exit-active {
    transform: translateX(20%);
    opacity: 0;
    transition: transform 150ms, opacity 150ms;
}
