@use '~@edwin-edu/ui/vars' as *;

.modal {
  width: #{480 + $space-6x * 2};
  padding: $space-5x $space-6x;
}

.actionButtons {
  button {
    text-transform: capitalize;
  }
}

.truncate {
  @include lineClamp(2);
}

.subjectsList {
  height: 360px;
  overflow-y: auto;
  @include scrollbar();
}

.loadingSubjects {
  height: 100%;
}
