@use '~@edwin-edu/ui/vars' as *;

.container {
  position: relative;
  margin-bottom: $space-2x;

  button {
    justify-content: flex-start;
  }
}
.dropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;

  > button {
    border-radius: 10px 10px 0 0;
  }

  hr {
    position: sticky;
    top: 2.5rem;
    margin: 0 $space-3x $space-2x;
    opacity: 0.7;
  }

  ul {
    max-height: 200px;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 0.5rem;
    }

    button {
      width: 100%;
      height: 1.75rem;
      padding: 0 0.875rem;
      font-weight: 400;
      &:hover:enabled {
        background: $color-base-300;
        color: $white;
        span {
          font-weight: 600;
        }
      }
    }
  }
}
.dropdownOpen {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);

  > button {
    box-shadow: none;
  }
}

@mixin arrowStyles($open: false) {
  ::after {
    content: '';
    position: absolute;
    right: 14px;
    width: 8px;
    height: 8px;
    border: solid $color-neutral-500;
    border-width: 0 1px 1px 0;
    @if $open {
      top: 18px;
      transform: rotate(225deg) skew(-7deg, -7deg);
    } @else {
      top: 14px;
      transform: rotate(45deg) skew(-7deg, -7deg);
    }
  }
}

.dropdownButton {
  position: relative;
  width: 100%;
  height: 2.5rem;
  padding: 0 0.875rem;
  background: $white;
  border-radius: 10px;
  font-weight: 600;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border: none !important;
  &:active {
    color: $black;
  }
  @include arrowStyles($open: false);
  span {
    width: 190px;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
  }
}
.rotateArrow {
  @include arrowStyles($open: true);
}
