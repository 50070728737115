@use '~@edwin-edu/ui/vars' as *;

.badge {
  background-color: $color-base-300;
  border-radius: $space-5x;
  font-size: 12px;
}

.popover {
  width: 280px;
  padding: $space-2x $space-1x $space-2x $space-2x;
  max-height: 160px;
  overflow-y: auto;
  @include scrollbar();

  ul {
    margin: 0;
    padding: 0 $space-1x 0 $space-5x;
    color: $color-base-300;

    li::marker {
      font-size: 12px;
    }
  }
}
