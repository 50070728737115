@use '@edwin-edu/ui/vars' as *;

.header {
  height: fit-content;
}

.panelTitle {
  color: $color-neutral-300;
  font-size: 20px;
  font-weight: 600;
}
