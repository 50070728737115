@use '~@edwin-edu/ui/vars' as *;

.actionButton {
  padding: $space-2x $space-4x;

  &:first-child {
    padding-left: 0;
  }
}

.vr {
  display: inline-block;
  border-right: 1px solid $color-neutral-300;
  height: $space-5x;
}

.container {
  @include scrollbar;

  & {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }
}

.cardsContainer {
  @include scrollbar;

  & {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow-y: auto;
  }
}

.classCards {
  flex: 1 1 auto;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, 335px);
  grid-template-rows: repeat(auto-fit, 212px);
  grid-gap: 20px 20px;
  padding: 0;
  margin: 0;
}

@keyframes popoverFadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.archivedClassButton {
  color: $color-neutral-500;
  font-weight: 700;
}

.pieIcon {
  position: absolute;
  top: 2.5px;
  left: 1px;
}