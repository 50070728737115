@use '@edwin-edu/ui/vars' as *;

.questionContainer {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: $color-neutral-500;
}
.mid {
  font-size: 1.625rem;
}
.large {
  font-size: 2.125rem;
}
.gridContainer {
  display: flex;
  flex-wrap: wrap;
  padding-top: 12px;
}

.optionContainer {
  position: relative;
  background: $color-neutral-50;

  border-radius: 8px;
  margin: 5px;
  padding: 6px 12px;
  border: 1px solid $color-neutral-200;
  cursor: pointer;
  user-select: none;

  &:active {
    background: $color-base-300;
  }
}

.optionVerticalLayout {
  width: 720px;
}

.optionImageLayout {
  width: 360px;
  min-height: 200px;
  vertical-align: bottom;
  background: none;
  border: none;
}

.optionImageContainer {
  padding-bottom: 4px;
  height: 190px;
  img {
    height: 100%;
    max-width: 190px;
    border-radius: 12px;
    object-fit: contain;
  }
}

.selected {
  background: $color-base-300;
  color: $color-neutral-50;

  table, th, tr, td {
    border-color: $color-neutral-0 !important;
  }
}

.correctAnswer {
  outline: 3px solid $color-info-success;
  outline-offset: -2px;
}

.reviewIcon {
  position: absolute;
  right: -35px;
  top: 4px;
  transform: scale(1.1);
}

.reviewIconImg {
  @extend .reviewIcon;
  right: 20px;
}

.highlight {
  background-color: $color-base-200 !important;
  outline: 1px solid $color-neutral-400 !important;
  border-radius: $radius-2x;
}
