@use '~@edwin-edu/ui/vars' as *;

.header {
  display: inline-flex;
  align-items: center;
  height: 45px;
}

.lastEdited {
  height: 20px;
  padding-top: 5px;
  display: flex;
  align-items: center;
  color: $color-neutral-300;
  font-style: italic;
}

.topButtonsContainer {
  position: absolute;
  display: flex;
  gap: 10px;
  right: 41px;
  top: 3px;
}

.mathToolImg {
  border: 1px solid $color-neutral-300;
  margin: 0 auto;
  height: 100%;
}

.infoContainer {
  display: flex;
  // -webkit-box-align: center;
  align-items: center;
  justify-content: space-evenly;
  height: 32px;
  margin: 15px 0px;
}

.leftContainer {
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
}

.dateContainer {
  margin-left: auto;
  font-size: 15px;
  font-style: italic;
  letter-spacing: -0.4px;
  color: $color-neutral-400;
}

.dateTime {
  display: flex;
  flex-direction: column;
  margin-right: 10px;

  p {
    margin-left: auto;
  }
}

.notesTitle {
  font-size: 20px;
  color: $color-neutral-300;
  font-weight: 600;
}

.notesRichText {
  min-height: 150px;
  color: $color-neutral-200;
}

.buttonImage {
  margin: 0 auto;

}

.highlightsTopContainer {
  display: flex;
  align-items: start;
  font-size: 16px;
}

.switchContainer {
  margin-left: auto;

  span {
    padding-right: 10px;
  }
}

.highlightArea {
  display: flex;
  width: 100%;
  border-top: 1px solid #d8d8d8;
  padding: 1em 0;
}

.highlightText {
  display: inline-block;
  padding-left: 20px;
  font-size: 16px;
  color: #4a4a4a;
  letter-spacing: -0.4px;

  svg {
    vertical-align: unset;
  }
}

.dot {
  margin-top: 1.5%;
  position: relative;
  height: 10px;
  width: 10px;
  min-width: 10px;
  border-radius: 50%;
}
.dot1 {
  @extend .dot;
  background-color: #92ffac;
}
.dot2 {
  @extend .dot;
  background-color: #ffaecf;
}
.dot3 {
  @extend .dot;
  background-color: #ffe884;
}

.notVisible {
  display: none;
}

.visible {
  display: block;
}
