@use '../../vars' as *;

.popover {
  position: relative;
  box-sizing: border-box;
  overflow-y: auto;
  padding: $space-2x $space-3x;
  background-color: $color-neutral-0;
  border-radius: $radius-1x;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  max-width: 700px;
  max-height: calc(100vh - 24px);
}

.closeButton {
  position: absolute;
  top: $space-2x;
  right: $space-2x;
}
