@use '../../../vars' as *;

.outerAuthContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerAuthContainer {
  position: relative;
  width: 455px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $space-6x $space-10x $space-8x;
  z-index: 10;
  color: $color-neutral-500;
  line-height: normal;
  letter-spacing: -1px;
  opacity: 0.95; /* TODO: opacity: 0.9 with transform: translate(-50%, -50%); is causing blurry text when editing input fields */
  box-shadow: 0 8px 12px 0 rgba(74, 74, 74, 0.25);
  border-radius: 8px;
  /* border color: */
  background: linear-gradient(
    93.02deg,
    rgba($color-base-300, 0.25),
    rgba(255, 255, 255, 0.29) 79.51%
  );

  /* actual background color: */
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: 1px;
    border-radius: inherit;
    background: linear-gradient(
      115deg,
      #fff 38.57%,
      rgba(255, 255, 255, 0.4) 100%
    );
  }
}

.showError {
  border: solid 2px red;
}

.welcomeMessage {
  position: relative;
  margin: $space-3x 0;
  font-weight: 400;
  font-size: 38px;
  text-align: center;
  line-height: 40px;
  min-height: 45px;
  display: flex;
  align-items: center;

  span {
    color: $color-base-300;
  }
}

.edwinLogo {
  margin-left: $space-1x;
  margin-bottom: 1px;
  vertical-align: unset;
  height: 29px
}
