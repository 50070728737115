@use '@edwin-edu/ui/vars' as *;

.panel {
  position: relative;
}

.closeButton {
  @include svgHoverStyles($color-base-300, $color-base-400, $color-base-400);

  & {
    position: absolute;
    padding: $space-2x;
    top: $space-1x;
    right: $space-2x;
  }
}
