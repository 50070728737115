@use '~@edwin-edu/ui/vars' as *;

.headerSubtitle {
  color: $color-neutral-500;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: $space-3x;
}
.dashboardRow {
  height: 50px;
  position: relative;
  align-items: center;
}
.errorIcon {
  width: 14px;
  height: 14px;
  margin-bottom: 2px;
}
.classesIcon {
  width: 75px;
  margin-right: 15px;
}
.notesIcon {
  width: 70px;
  margin: 10px 0 0 15px;
}
