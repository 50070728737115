@use '../../../vars' as *;

.tryAgainButton {
  //Button
  margin: 25px auto 0;
}
.number {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 14px;
  letter-spacing: -0.5px;
  margin-top: 5px;
}
.blue {
  color: $color-base-300;
}
.title {
  //h1
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.6px;
  margin: 15px auto 25px;
}
.desc {
  //p
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.1px;
  width: 400px;
  margin: 0 auto;
  padding-bottom: 15px;
}
.subDesc {
  //p
  font-weight: 400;
  color: $color-base-300;
  font-size: 13px;
  letter-spacing: -0.3px;
  width: 400px;
  margin: 10px auto;
  padding-bottom: 10px;
}
.contact {
  //a
  font-weight: 700;
  text-decoration: none;
  color: $color-base-300;
  padding-left: 2px;
}
.container {
  padding: 20px 0;
  text-align: center;
}
