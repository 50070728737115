@use '@edwin-edu/ui/vars' as *;

.borderTop {
  border-top: 1px solid $color-neutral-200;
}

.teacherNotesTitle {
  font-weight: 400;
  font-size: 16px;
  color: #003b61;
  letter-spacing: -0.4px;
  display: inline;
}

.notesToggle {
  display: inline;
  margin-left: 10px;
  font-size: 14px;
  color: $color-neutral-500;
  position: relative;
  top: -2px;
}

.notesToggle.active {
  color: $color-base-300;
}

.infoIcon {
  width: 24px;
  height: 24px;

  & path {
    fill: $color-base-300;
  }

  & circle {
    stroke: $color-base-300;
    stroke-width: 2;
  }
}

.modal {
  margin: $space-10x;
  max-width: 1200px;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: $space-2x;
  min-height: 100px;
  padding: $space-2x 0;
  border-bottom: 1px solid $color-neutral-100;

  span[class$="clamped"] {
    font-size: 20px;
    line-height: 1;
    color: $color-neutral-300;
  }
}

.itemTitle {
  width: 100%;
  color: $color-base-300;
  text-align: left;
  @include body-large(false);
  
  span {
    font-weight: 600;
    line-height: 1.07;
    @include lineClamp(3);
  }
}

.openNotesButton {
  & svg {
    margin-bottom: 3px;
  }

  & path {
    stroke: $color-base-300;
  }

  &:hover path {
    stroke: $color-neutral-0;
  }
}
.headerContainer {
  margin: 13px 12.5px 0;
}

.header {
  height: 32px;
}